<template>
  <div>
    <section class="p-0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="error-section">
              <h1>{{ error.statusCode }}</h1>
              <h2 v-if="error.statusCode === 404">{{ $t('error.pageNotFound') }}</h2>
              <h2 v-else>{{ $t('error.errorOccured') }}</h2>
              <nuxt-link :to="localePath({ path: '/' })" class="btn btn-solid">{{ $t('error.backHome') }}</nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ['error'],
}
</script>
