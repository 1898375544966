import { makeCatalogSlug, makeArticleSlug } from './makeCatalogSlug';

export function stringToHash(string) {
  let hash = 0;

  if (string.length == 0) {
    return hash;
  }

  for (let i = 0; i < string.length; i++) {
    let char = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }

  return hash;
}

export default $axios => ({
  async getCategory(showHidden = false) {
    const categories = await $axios.$get('/product/category' + ((showHidden) ? '?showHidden=true' : ''));
    return categories.map((category) => ({
      ...category,
      slug: makeCatalogSlug(category),
      imageUrl: category.imageUrl ? `${process.env.BASE_API_URL}${category.imageUrl}` : undefined,
    }));
  },

  async getArticle(catalogId = '', showHidden = false) {
    let articles;
    if (catalogId) {
      articles = await $axios.$get(`/product/article/category/${catalogId}`);
    } else {
      articles = await $axios.$get('/product/article' + ((showHidden) ? '?showHidden=true' : ''));
    }
    return articles.map((article) => ({
      ...article,
      catalog: {
        ...article.catalog,
        slug: makeCatalogSlug(article.catalog),
      },
      slug: makeArticleSlug(article),
      imageUrl: article.imageUrl ? `${process.env.BASE_API_URL}${article.imageUrl}` : undefined,
      maxLimitByMonth: article.maxLimitByMonth ? article.maxLimitByMonth : 0
    }));
  },

  async getTop10Article() {
    let articles = await $axios.$get('/product/article-top-10');

    return articles.map((article) => ({
      ...article,
      catalog: {
        ...article.catalog,
        slug: makeCatalogSlug(article.catalog),
      },
      slug: makeArticleSlug(article),
      imageUrl: article.imageUrl ? `${process.env.BASE_API_URL}${article.imageUrl}` : undefined,
      maxLimitByMonth: article.maxLimitByMonth ? article.maxLimitByMonth : 0
    }));
  },

  // Admin
  patchArticle(article) {
    return $axios.$patch(`/product/article/${article.id}`, {
      descriptionFr: article.description.fr ? article.description.fr : undefined,
      descriptionEn: article.description.en ? article.description.en : undefined,
      maxLimitByMonth: article.maxLimitByMonth,
      stockThreshold: article.stockThreshold,
      hidden: article.hidden,
      batchSize: article.batchSize,
    });
  },

  patchCatalog(catalog) {
    return $axios.$patch(`/product/category/${catalog.id}`, {
      labelFr: catalog.label.fr ? catalog.label.fr : undefined,
      labelEn: catalog.label.en ? catalog.label.en : undefined
    });
  },

  postArticleImage(article, image) {
    let data = new FormData();
    const extension = image.name.split('.').pop() || 'jpg';
    data.append('file', image, article.slug + '.' + extension);
    data.append('articleId', article.sageId);
    // Adapted API to be able to handle articleIds with '/' in it
    //return $axios.$post(`/product/article/${article.id}/image`, data, {
    return $axios.$post(`/product/article/image`, data, {
      headers: {
        'accept': 'application/json',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      }
    });
  },

  postCatalogImage(catalog, image) {
    let data = new FormData();
    data.append('file', image, catalog.slug);
    return $axios.$post(`/product/catalog/${catalog.id}/image`, data, {
      headers: {
        'accept': 'application/json',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      }
    });
  },

  deleteArticleImage(articleId) {
    //return $axios.delete(`/product/article/${articleId}/image`);
    // Adapted API to be able to handle articleIds with '/' in it
    return $axios.delete(`/product/article/image`, { data: { articleId: articleId } });
  },

  deleteCatalogImage(catalogId) {
    return $axios.delete(`/product/catalog/${catalogId}/image`);
  },

  synchronize() {
    return $axios.$get('/product/synchronize');
  }
});