<template>
  <div>
    <Cookie />
    <Header @logout="logout" />
    <nuxt id="body-content"/>
    <Footer />
    <div class="tap-top" v-scroll-to="'#body-content'">
      <div>
          <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
      </div>
    </div>
    <template v-if="enableStatistics">
      <!-- Global site tag (gtag.js) - Google Analytics -->
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-124425910-1"></script>
      <script>
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-124425910-1');
      </script>
    </template>
  </div>
</template>

<script>
import Header from '@/components/header';
import Footer from '@/components/footer';
import Cookie from '@/components/cookie';
import { getTranslatedCatalogLabel } from '@/utils/entity-translation';
import UserRole from '@/models/user-role';
import OrderStatus from "@/models/order-status";

export default {
  head() {
    return {
      title: 'Smilers eBoutique'
    }
  },
  components: {
    Header,
    Footer,
    Cookie,
  },
  data() {
    return {
      expirationTimeout: null,
    }
  },
  watch: {
    '$route.query': '$fetch',
    '$auth.state.exp': function() {
      this.handleTokenExpiration();
    },
  },
  async fetch() {
    if (this.$auth.loggedIn) {
      // Fetch user info
      if (Object.keys(this.$auth.user).length === 0) {
        if (this.$auth.$storage.getUniversal('accessRole') === UserRole.ADMIN) {
          this.$auth.setUser({ username: this.$auth.$storage.getUniversal('username'), accessRole: this.$auth.$storage.getUniversal('accessRole') });
        } else {
          const successUser = await this.$api.user.getMe();
          this.$auth.setUser(successUser);
        }
      }

      // Fetch Catalog
      if (this.$store.state.menu.catalogLocale !== this.$i18n.locale) {
        try {
          const categories = await this.$api.product.getCategory(this.$auth.user.accessRole === UserRole.ADMIN);
          this.$store.dispatch('products/setCatalogs', categories);
          this.$store.dispatch('menu/appendCatalogMenu', categories.map(category => ({
            title: `${getTranslatedCatalogLabel(category, this.$i18n.locale)} (${category.articlesCount})`,
            path: `/catalog/${category.slug}`,
            type: 'link',
          })));
          this.$store.dispatch('menu/setCatalogMenuLocale', this.$i18n.locale);
        } catch (err) {
          console.error(err);
        }
      }

      if (this.$auth.$storage.getUniversal('accessRole') !== UserRole.ADMIN) {
        // Check active order
        const orders = await this.$api.order.getOrders();
        const activeOrder = orders.find(order => order.status === OrderStatus.ACTIVE);
        if (activeOrder) {
          this.$store.dispatch('order/setActiveOrder', activeOrder);
        }

        // Get Monthly limit
        const limits = await this.$api.order.getMonthlyLimit();
        this.$store.dispatch('order/setMonthlyLimit', limits);
      }
    }
  },
  async mounted() {
    await this.handleTokenExpiration();

    this.$nextTick(() => {
      this.$nuxt.$loading.start()
      setTimeout(() => this.$nuxt.$loading.finish(), 3000)
    })
  },
  computed: {
    enableStatistics() {
      return process.env.APP_ENV === 'production' && window.localStorage.getItem('cookie-statistics') === true;
    }
  },
  methods: {
    async logout() {
      await this.$auth.logout();
      localStorage.clear();
      if (this.expirationTimeout) {
        clearTimeout(this.expirationTimeout);
        this.expirationTimeout = null;
      }
      this.$router.go(this.localePath('/'));
    },
    async handleTokenExpiration() {
      if (this.$auth.loggedIn && !this.expirationTimeout) {
        const expirationTokenDate = this.$auth.$storage.getUniversal('exp');
        if (expirationTokenDate) {
          const expirationInMs = Math.round(this.$moment.duration(
            this.$moment(expirationTokenDate).diff(this.$moment())
          ));
          
          if (expirationInMs <= 0) {
            await this.logout();
          } else {
            this.expirationTimeout = setTimeout(() => this.logout(), expirationInMs);
          }
        }
      }
    }
  }
}
</script>
