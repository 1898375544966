export function getTranslatedCatalogLabel(entity, lang) {
  // Entity can't be translated
  if (entity === undefined || ((!entity.label || !entity.labelTranslated) && !entity.name)) {
    return null;
  }

  // Translation found
  if (entity.labelTranslated[lang] && entity.label[lang]) {
      return entity.label[lang];
  }

  // Fallback to FR
  const fallBackLang = 'fr';
  if (entity.labelTranslated[fallBackLang] && entity.label[fallBackLang]) {
    return entity.label[fallBackLang];
  }

  // Fallback to name
  return entity.name;
}

export function getTranslatedDescription(entity, lang) {
  // Entity can't be translated
  if (entity === undefined || !entity.description || !entity.descriptionTranslated) {
    return null;
  }

  // Translation found
  if (entity.descriptionTranslated[lang] && entity.description[lang]) {
      return entity.description[lang];
  }

  // Fallback to FR
  const fallBackLang = 'fr';
  if (entity.descriptionTranslated[fallBackLang] && entity.description[fallBackLang]) {
    return entity.description[fallBackLang];
  }

  return null;
}

export function getTranslatedArticleLabel(article, lang) {
  // Entity can't be translated
  if (article === undefined || !article.label) {
    return null;
  }

  // Translation found
  if (article.label[lang]) {
    return article.label[lang];
  }

  // Fallback to FR
  const fallBackLang = 'fr';
  if (article.label[fallBackLang]) {
    return article.label[fallBackLang];
  }

  return null;
}