import { makeCatalogSlug, makeArticleSlug } from './makeCatalogSlug';

export default $axios => ({
  // Constant
  ErrorsId: {
    ORDER_MONTHLY_LIMIT_EXCEED: "ORDER_MONTHLY_LIMIT_EXCEED"
  },
  // User
  async postOrder(cart, billingAddressId, deliveryAddressId) {
    return $axios.$post('/order', {
      items: cart.map(cartItem => ({
        articleId: cartItem.article.id,
        quantity: cartItem.quantity
      })),
      billingAddressId,
      deliveryAddressId
    });
  },
  async getOrders() {
    return $axios.$get('/order');
  },
  async cancelOrder(orderId) {
    return $axios.$delete(`/order/${orderId}`);
  },
  async getInvoice(file) {
    return $axios.$get(`/order/invoices/${file}`);
  },
  async getPaymentInfo(orderId, paymentProvider) {
    if (paymentProvider === undefined) {
      return $axios.$get(`/order/${orderId}/payment-info`);
    } else if (paymentProvider.toLowerCase() === 'ingenico') {
      return $axios.$get(`/order/${orderId}/payment-info-ingenico`);
    }
  },
  async getPaymentInfoIngenico(orderId) {
    return $axios.$get(`/order/${orderId}/payment-info-ingenico`);
  },
  async checkPaymentStatusIngenico(orderId) {
    return $axios.$get(`/order/${orderId}/payment-status-ingenico`);
  },
  // used to modify selected attributes
  async setPaid(reference) {
    return $axios.$put(`/order/setPaid/${reference}`, {}, { headers: { authorization: `${process.env.API_TOKEN}` } });
  },
  async updateOrderByRef(reference, data) {
    return $axios.$put(`/order/byRef/${reference}`, {
      smileys: data.smileys ? data.smileys : undefined
    });
  },
  async finalizeOrderByRef(reference, bankStatus) {
    return $axios.$put(`/order/finalizeByRef/${reference}`, { status: bankStatus });
  },
  async getMonthlyLimit() {
    return $axios.$get('/order/monthly-limit');
  },
  // Admin
  async getOrderList(includeArchives) {
    return $axios.$get('/order/list', { params: { archived: includeArchives } });
  },
  async updateOrder(orderId, data) {
    return $axios.$put(`/order/${orderId}`, {
      status: data.status ? data.status : undefined,
      trackingUrl: data.trackingUrl ? data.trackingUrl : undefined,
      invoiceFile: data.invoiceFileB64 ? data.invoiceFileB64 : undefined,
    });
  },
  async getLastPurchases() {
    let articles = await $axios.$get('/order/last-purchases');

    return articles.map((article) => ({
      ...article,
      catalog: {
        ...article.catalog,
        slug: makeCatalogSlug(article.catalog),
      },
      slug: makeArticleSlug(article),
      imageUrl: article.imageUrl ? `${process.env.BASE_API_URL}${article.imageUrl}` : undefined,
      maxLimitByMonth: article.maxLimitByMonth ? article.maxLimitByMonth : 0
    }));
  },
});