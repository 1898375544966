import products from '../../data/products'

const state = {
  catalogs: [],
  articles: [],
  currentCatalogSlug: null,
  currentArticleSlug: null,
  currentArticles: [],

  /**
   * TODO: Clean
   */
  products: products.data,
  wishlist: [],
  compare: [],
  order: [],
  locale: 'fr',
  searchProduct: [],
}
// getters
const getters = {
  articlesFromCatalog: (state) => {
    if (state.currentCatalogSlug !== null) {
      return state.articles.filter((article) => article.catalog.slug === state.currentCatalogSlug && !article.hidden);
    }
    return [];
  },
  articlesWithHidden: (state) => {
    return state.articles;
  },
  articles: (state) => {
    return state.articles.filter((article) => !article.hidden);
  },
  currentArticles: (state) => {
    return state.currentArticles;
  },
  currentCatalog: (state) => {
    return state.catalogs.find(catalog => catalog.slug === state.currentCatalogSlug);
  },
  currentCatalogSlug: (state) => {
    return state.currentCatalogSlug;
  },
  currentArticle: (state) => {
    return state.articles.find(article => article.slug === state.currentArticleSlug);
  },
  catalogs: (state) => {
    return state.catalogs.filter((catalog) => catalog.articlesCount > 0 || (
        catalog.subCatalog.length > 0
        && catalog.subCatalog.reduce((acc, val) => acc += val.articlesCount, 0) > 0
      )
    );
  },
  allCatalogs: (state) => {
    return [
      ...state.catalogs,
      ...state.catalogs
        .map((catalog) => catalog.subCatalog ? catalog.subCatalog.map((c) => ({...c, parent:catalog})) : [])
        .reduce((acc, next) => [...acc, ...next], [])
    ];
  },

  /**
   * TODO: Clean
   */
  getcollectionProduct: (state) => {
    return collection => state.products.filter((product) => {
      return collection === product.collection
    })
  },
  getProductById: (state) => {
    return id => state.products.find((product) => {
      return product.id === +id
    })
  },
  compareItems: (state) => {
    return state.compare
  },
  wishlistItems: (state) => {
    return state.wishlist
  },
  getOrder: (state) => {
    return state.order
  }
}
// mutations
const mutations = {
  setArticles: (state, payload) => {
    state.articles = payload;
  },
  setCatalogs: (state, payload) => {
    state.catalogs = payload;
  },
  setCurrentCatalogSlug: (state, payload) => {
    state.currentCatalogSlug = payload;
  },
  setCurrentArticleSlug: (state, payload) => {
    state.currentArticleSlug = payload;
  },
  setCurrentArticles: (state, payload) => {
    state.currentArticles = payload;
  },

  /**
   * TODO: Clean
   */
  addToWishlist: (state, payload) => {
    const product = state.products.find(item => item.id === payload.id)
    const wishlistItems = state.wishlist.find(item => item.id === payload.id)
    if (wishlistItems) {
      //
    } else {
      state.wishlist.push({
        ...product
      })
    }
  },
  removeWishlistItem: (state, payload) => {
    const index = state.wishlist.indexOf(payload)
    state.wishlist.splice(index, 1)
  },
  addToCompare: (state, payload) => {
    const product = state.products.find(item => item.id === payload.id)
    const compareItems = state.compare.find(item => item.id === payload.id)
    if (compareItems) {
      //
    } else {
      state.compare.push({
        ...product
      })
    }
  },
  removeCompareItem: (state, payload) => {
    const index = state.compare.indexOf(payload)
    state.compare.splice(index, 1)
  },
  searchProduct: (state, payload) => {
    payload = payload.toLowerCase()
    state.searchProduct = []
    if (payload.length) {
      state.products.filter((product) => {
        if (product.title.toLowerCase().includes(payload)) {
          state.searchProduct.push(product)
        }
      })
    }
  },
  createOrder: (state, payload) => {
    state.order = payload
  },
}
// actions
const actions = {
  setArticles: (context, payload) => {
    context.commit('setArticles', payload);
  },
  setCatalogs: (context, payload) => {
    context.commit('setCatalogs', payload);
  },
  setCurrentCatalogSlug: (context, payload) => {
    context.commit('setCurrentCatalogSlug', payload);
  },
  setCurrentArticleSlug: (context, payload) => {
    context.commit('setCurrentArticleSlug', payload);
  },
  setCurrentArticles: (context, payload) => {
    context.commit('setCurrentArticles', payload);
  },

  /**
   * TODO: Clean
   */
  addToWishlist: (context, payload) => {
    context.commit('addToWishlist', payload)
  },
  removeWishlistItem: (context, payload) => {
    context.commit('removeWishlistItem', payload)
  },
  addToCompare: (context, payload) => {
    context.commit('addToCompare', payload)
  },
  removeCompareItem: (context, payload) => {
    context.commit('removeCompareItem', payload)
  },
  searchProduct: (context, payload) => {
    context.commit('searchProduct', payload)
  },
  createOrder: (context, payload) => {
    context.commit('createOrder', payload)
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
