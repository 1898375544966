const OrderStatus = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  SUSPICIOUS_PAID: 'SuspiciousPaid',
  SUSPICIOUS_CANCELED: 'SuspiciousCanceled',
  PAID: 'Paid',
  DELIVERED: 'Delivered',
  ARCHIVED: 'Archived',
  ERROR: 'Error'
}

export default OrderStatus;
