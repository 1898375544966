import Product from "@/api/product";
import User from "@/api/user";
import Address from "@/api/address";
import Order from "@/api/order";
import Galaxy from "@/api/galaxy";

export default ({ $axios }, inject) => {
  const repositories = {
    product: Product($axios),
    user: User($axios),
    address: Address($axios),
    order: Order($axios),
    galaxy: Galaxy($axios),
  };

  inject("api", repositories);
};