import { configure, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

export default ({ app }) => {
    configure({
        defaultMessage: (field, values) => {
            values._field_ = app.i18n.t(`fields.${field}`);
            return app.i18n.t(`validation.${values._rule_}`, values);
        }
    });
}

extend("required", required);
