<template>
  <footer class="footer-black">
    <section class="section-b-space light-layout">
      <div class="container">
        <div class="row footer-theme partition-f">
          <div class="col-lg-4 col-md-6">
            <div class="footer-title footer-mobile-title">
              <h4>{{ $t("footer.about") }}</h4>
            </div>
            <div class="footer-contant">
              <div class="footer-title">
                 <img
                  style="max-width: 200px"
                  src="../assets/images/smilers-logo_white.png"
                  class="img-fluid logo mb-3"
                  alt="logo"
                />
              </div>
              <p>
                {{ $t("footer.slogan") }}
              </p>
              <p id="made_in_france">
                * {{ $t("footer.made_in_france") }}
              </p>
            </div>
          </div>
          <div class="col offset-lg-2 offset-sm-0">
            <div class="sub-title">
              <div class="footer-title">
                <h4>{{ $t("footer.useful") }}</h4>
              </div>
              <div class="container-fluid row mx-auto px-0 mb-2">
                <div class="gold-gradient gold-separator col"></div>
              </div>
              <div class="footer-contant">
                <ul>
                  <li>
                    <a href="#" @click.prevent="getfile(ml)">{{
                      $t("footer.legalNotice")
                    }}</a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="getfile(cgv)">{{
                      $t("footer.termsOfSales")
                    }}</a>
                  </li>
                  <li>
                    <nuxt-link :to="{ path: '/cookie-policy' }">{{
                      $t("navigation.cookiePolicy")
                    }}</nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="sub-title">
              <div class="footer-title">
                <h4>{{ $t("footer.contactUs") }}</h4>
              </div>
              <div class="container-fluid row mx-auto px-0 mb-2">
                <div class="gold-gradient gold-separator col"></div>
              </div>
              <div class="footer-contant">
                <ul class="contact-list">
                  <li>
                    <i class="fa fa-map-marker"></i>
                    Biotech Dental Smilers<br />
                    167 Impasse Gaspard Monge<br />
                    13300 Salon-de-Provence<br />
                    FRANCE
                  </li>
                  <li>
                    <i class="fa fa-phone"></i>
                    <a href="tel:+33413228240">+33 (0)4 13 22 82 40</a>
                  </li>
                  <li>
                    <i class="fa fa-envelope-o"></i>
                    <a href="mailto:info@smilers.com">info@smilers.com</a>
                  </li>
                  <li>
                    <i class="fa fa-globe"></i>
                    <a href="https://www.smilers.com/">smilers.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="sub-footer">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <div class="footer-end">
              <p>
                Copyright
                <i class="fa fa-copyright" aria-hidden="true"></i> 2021 Smilers.
                {{ $t("footer.allRightsReserved") }}  {{ $t("footer.nonContractualPicture")}}
              </p>
            </div>
          </div>
          <div class="col-xl-6 col-md-6 col-sm-12">
            <div class="footer-social pb-4">
              <ul>
                <li>
                  <a href="https://www.facebook.com/SmilersAligners/">
                    <i class="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/smilers_aligners/?hl=fr">
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/user/marketingbiotecheos">
                    <i class="fa fa-youtube-play fa-2x" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      ml: "",
      cgv: ""
    };
  },
  created() {
    this.ml = this.$i18n.locale
      ? `/mentions_legale_${this.$i18n.locale}.pdf`
      : "/mentions_legale_fr.pdf";
    this.cgv = this.$i18n.locale
      ? `/cgv_${this.$i18n.locale}.pdf`
      : "/cgv_fr.pdf";
  },
  mounted() {
    this.ml = this.$i18n.locale
      ? `/mentions_legale_${this.$i18n.locale}.pdf`
      : "/mentions_legale_fr.pdf";
    this.cgv = this.$i18n.locale
      ? `/cgv_${this.$i18n.locale}.pdf`
      : "/cgv_fr.pdf";
  },
  beforeUpdate() {
    this.ml = this.$i18n.locale
      ? `/mentions_legale_${this.$i18n.locale}.pdf`
      : "/mentions_legale_fr.pdf";
    this.cgv = this.$i18n.locale
      ? `/cgv_${this.$i18n.locale}.pdf`
      : "/cgv_fr.pdf";
  },
  methods: {
    getfile(file) {
      window.open(file, "_blank");
    }
  }
};
</script>

<style lang="scss">
.footer-black {
  .gold-separator {
    height: 1px;
    max-width: 95%;
  }
  h4 {
    font-size: 18px !important;
    margin-bottom: 10px !important;
    letter-spacing: 0.25rem !important;
    font-weight: 700 !important;
    color: white;
    text-transform: none !important;
  }

  li {
    line-height: 2em !important;
  }
}
</style>
