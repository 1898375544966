import Menu from '../../data/menu'

const state = {
  data: Menu.data,
  catalogLocale: null,
}

// getters
const getters = {
  navigationCatalogs: (state) => {
    const index = state.data.findIndex(item => item.title === 'navigation.catalogs');
    if (index >= 0) {
      return state.data[index].children;
    }
    return [];
  }
}

// mutations
const mutations = {
  appendCatalogMenu: (state, payload) => {
    const index = state.data.findIndex(item => item.title === 'navigation.catalogs');
    if (index >= 0) {
      state.data[index].children = payload;
    }
  },
  setCatalogMenuLocale: (state, payload) => {
    state.catalogLocale = payload;
  },
}

// actions
const actions = {
  appendCatalogMenu: (context, payload) => {
    context.commit('appendCatalogMenu', payload)
  },
  setCatalogMenuLocale: (context, payload) => {
    context.commit('setCatalogMenuLocale', payload);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
