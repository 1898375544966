<template>
  <div class="container-fluid p-0">
    <div class="row mr-1 ml-1 hide-mobile topheader">
      <div class="col-sm-7 top-subheaderrow">
        <ul>
          <li>
            <a href="https://www.facebook.com/SmilersAligners/">
              <i class="fa fa-facebook fa-2x" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/smilers_aligners/?hl=fr">
              <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/user/marketingbiotecheos">
              <i class="fa fa-youtube-play fa-2x" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <div class="vr"></div>
        <ul>
          <li>
            <a href="tel:+33413228240">04 13 22 82 40</a>
          </li>
          <li>
            <a href="mailto:info@smilers.com">info@smilers.com</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-5 subheaderrow-right">
        <div class="pro-space-btn">
          <a href="https://smilers.biotech-dental.com/fr/login">{{
            $t("navigation.proSpace")
          }}</a>
        </div>
      </div>
    </div>
    <header class="row m-0">
      <!-- Smilers Logo Desktop -->
      <div class="col-sm-4 offset-md-4 text-center">
        <nuxt-link :to="localePath('/')" class="hide-mobile">
          <img
            style="max-width: 100%; width: 300px"
            src="../assets/images/smilers-logo_white.png"
            class="img-fluid logo mb-3"
            alt="logo"
          />
        </nuxt-link>
      </div>

      <!-- Smilers Logo Mobile -->
      <div class="col-sm-4 menu-actions offset-sm-4 offset-md-0">
        <nuxt-link :to="localePath('/')" class="show-mobile mb-2">
          <img
            src="../assets/images/smilers-logo_white.png"
            class="img-fluid logo mb-3"
            alt="logo"
          />
        </nuxt-link>

        <!-- Admin -->
        <div class="d-flex align-items-center align-content-center">
          <template v-if="!$auth.loggedIn">
            <nuxt-link
              :to="localePath('/account/login/practitioner')"
              :title="$t('navigation.login')"
            >
              <i class="fa fa-user fa-2x mr-2"></i> {{ $t("navigation.login") }}
            </nuxt-link>
          </template>
          <template v-else>

            <!-- User -->
            <template v-if="$auth.user.accessRole !== UserRole.ADMIN">
              <!-- Account -->
              <div class="onhover-dropdown">
                <nuxt-link :to="localePath('/account')">
                  <i class="fa fa-user fa-2x"></i>
                </nuxt-link>
                <!-- Account -->
                <ul class="onhover-show-div menu-onhover-show-div">
                  <!-- My account -->
                  <li>
                    <nuxt-link :to="localePath('/account')">{{
                      $t("navigation.account")
                    }}</nuxt-link>
                  </li>
                  <!-- Logout -->
                  <li>
                    <a href="" @click.prevent="logout">{{
                      $t("navigation.logout")
                    }}</a>
                  </li>
                </ul>
              </div>
              <!-- Cart -->
              <div class="onhover-dropdown">
                <div>
                  <nuxt-link :to="localePath('/order/cart')">
                    <template v-if="activeOrder === null">
                      <i class="fa fa-shopping-bag fa-2x"></i>
                      <span class="cart_qty_cls">{{ cartCount }}</span>
                    </template>
                    <template v-else>
                      <span class="fa fa-2x fa-eur"></span>
                    </template>
                  </nuxt-link>
                </div>
                <ul class="onhover-show-div cart-div hide-mobile">
                  <template v-if="activeOrder === null">
                    <template v-if="cart.length <= 0">
                      <li class="pb-3" style="color: black">
                        {{ $t("shoppingCart.empty") }}
                      </li>
                    </template>
                    <template v-else>
                      <li v-for="(articleInCart, index) in cart" :key="index">
                        <div class="media">
                          <nuxt-link
                            :to="
                              localePath(
                                `/catalog/${articleInCart.article.catalog.slug}/${articleInCart.article.slug}`
                              )
                            "
                          >
                            <img
                              alt
                              class="mr-3 cartThumb"
                              :src="
                                articleInCart.article.imageUrl
                                  ? articleInCart.article.imageUrl
                                  : defaultImage
                              "
                            />
                          </nuxt-link>
                          <div class="media-body">
                            <nuxt-link
                              :to="
                                localePath(
                                  `/catalog/${articleInCart.article.catalog.slug}/${articleInCart.article.slug}`
                                )
                              "
                            >
                              <h4>
                                {{
                                  getTranslatedArticleLabel(
                                    articleInCart.article,
                                    $i18n.locale
                                  )
                                }}
                              </h4>
                            </nuxt-link>
                            <h4>
                              <span
                                >{{ articleInCart.quantity }} x
                                {{ articleInCart.article.priceTTC }}€</span
                              >
                            </h4>
                          </div>
                        </div>
                        <div class="close-circle">
                          <a
                            @click="removeCartItem(articleInCart)"
                            :title="$t('shoppingCart.remove')"
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </a>
                        </div>
                      </li>
                      <li class="li-total">
                        <div class="total">
                          <h5>
                            {{ $t("shoppingCart.total") }} :
                            <span>{{ cartTotal }}€</span>
                          </h5>
                        </div>
                      </li>
                      <li class="buttons-container">
                        <div class="buttons">
                          <nuxt-link
                            :to="localePath('/order/cart')"
                            :class="'view-cart'"
                          >
                            {{ $t("shoppingCart.viewCart") }}
                          </nuxt-link>
                        </div>
                      </li>
                    </template>
                  </template>
                  <template v-else>
                    <li class="text-center">
                      {{ $t("checkout.activeOrderAlreadyExists") }}<br />
                      <nuxt-link
                        class="btn btn-solid"
                        :to="localePath('/order/checkout')"
                        >{{ $t("checkout.toActiveOrder") }}</nuxt-link
                      >
                    </li>
                  </template>
                </ul>
              </div>
              <div
                class="show-mobile show-pointer"
                @click="openmobilenav = true"
              >
                <i class="fa fa-bars fa-2x"></i>
              </div>
            </template>
            <!-- Admin -->
            <template v-else>
              <!-- Dashboard -->
              <nuxt-link :to="localePath('/admin')">
                <i class="fa fa-briefcase fa-2x"></i>
              </nuxt-link>
              <!-- Account -->
              <div class="onhover-dropdown">
                <a>
                  <i class="fa fa-user fa-2x"></i>
                </a>
                <ul class="onhover-show-div menu-onhover-show-div">
                  <li>
                    <a href="" @click.prevent="logout">{{
                      $t("navigation.logout")
                    }}</a>
                  </li>
                </ul>
              </div>
            </template>
          </template>
          <!-- Lang Switch -->
          <div class="onhover-dropdown traduction">
            <div class="lang-switch-btn">
              {{ $i18n.locale }}
            </div>
            <ul class="onhover-show-div lang-list">
              <li>
                <nuxt-link :to="switchLocalePath('fr')">{{
                  $t("lang.fr")
                }}</nuxt-link>
              </li>
              <li>
                <nuxt-link :to="switchLocalePath('en')">{{
                  $t("lang.en")
                }}</nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
    <!-- Golden separator -->
    <div class="container-fluid row mx-auto p-0">
      <div class="gold-gradient gold-separator col"></div>
    </div>
    <!-- Sub header catalog NAV -->
    <Nav
      :openmobilenav="openmobilenav"
      @closeMobileNav="openmobilenav = false"
    />
  </div>
</template>
<script>
import { getTranslatedArticleLabel } from "@/utils/entity-translation";
import UserRole from "@/models/user-role";
import { mapGetters } from "vuex";
import Nav from "@/components/catalog-nav.vue";
import defaultImage from "@/assets/images/2.jpg";

export default {
  data() {
    return {
      UserRole,
      openmobilenav: false,
      defaultImage,
    };
  },
  components: {
    Nav,
  },
  computed: {
    ...mapGetters({
      cart: "cart/cartItems",
      cartCount: "cart/cartItemsCount",
      cartTotal: "cart/cartTotalAmount",
      activeOrder: "order/activeOrder",
    }),
  },
  methods: {
    removeCartItem: function (article) {
      this.$store.dispatch("cart/removeCartItem", article);
    },
    logout() {
      this.$emit("logout");
    },
    getTranslatedArticleLabel,
  },
};
</script>

<style lang="scss">
@import "./assets/scss/theme/variables";
.subheaderrow-right {
  display: flex;
  justify-content: flex-end;
  font-size: small;
  padding: 10px;
  color: $black;
  a {
    border-style: solid;
    border-width: 2px;
    border-style: solid;
    border-width: 2px;
    border-image: linear-gradient(#f9d49f, #c89a72, #fddec4, #ecbc83, #f3ca9e) 1;
    padding: 2px;
    color: $black;
    &:hover {
      background: var(--theme-deafult);
      color: white;
      border-image: var(--theme-deafult);
    }
  }
}
.hovermethemecolor {
  &:hover {
    color: var(--theme-deafult);
  }
}

header {
  background-color: black;
  color: white;
  padding: 1em 1em 0 1em;
  .coordinates {
    font-size: 1.1em;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    padding: 0 1em;

    .pro-space-btn {
      margin-top: 10px;
      background: linear-gradient(
        90deg,
        #f9d49f 0.56%,
        #fffad2 25.28%,
        #c89a72 59.55%,
        #ecbc83 87.07%,
        #fddec4 100%
      );
      display: inline-block;
      padding: 5px 2px 6px 2px;
      a {
        white-space: nowrap;
        font-size: 0.9em;
        text-align: center;
        text-transform: uppercase;
        padding: 0.5em;
        color: black;
        background: white;
      }
    }
  }

  .menu-actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 2em;

    .d-flex {
      gap: 2em;
    }

    a {
      display: flex;
      align-items: center;
      color: white;

      &:hover {
        color: var(--theme-deafult);
      }
    }
  }

  .onhover-dropdown {
    padding-right: 16px;
  }
  .onhover-show-div {
    top: 2em !important;
  }

  .cart_qty_cls {
    top: -60% !important;
  }

  .cart-div {
    color: black;
    min-width: 360px;
    width: 25vw;
    position: relative;

    &:last-child {
      font-size: 18px;
      padding-bottom: 0;
    }

    .buttons-container {
      width: 100%;
      padding-bottom: 10px;
    }
    .media {
      img {
        height: 90px;
      }
      .media-body {
        align-self: center;
        h4 {
          color: $font-color;
          span {
            color: $grey;
            font-size: 16px;
          }
        }
      }
    }
    .close-circle {
      position: absolute;
      top: 2px;
      right: 10px;
      i {
        color: $grey-light;
        transition: all 0.3s ease;
        &:hover {
          color: $black;
          transition: all 0.3s ease;
        }
      }
    }
    .total {
      border-top: 1px solid $border-grey;
      border-bottom: 1px solid $border-grey;
      padding-top: 10px;
      padding-bottom: 10px;
      h5 {
        text-transform: capitalize;
        margin-bottom: 0;
        color: $header-font;
        span {
          float: right;
        }
      }
    }
    .buttons {
      a {
        font-size: 16px;
        color: $font-color;
        font-weight: 700;
        &:hover {
          color: var(--theme-deafult);
        }
      }
      .checkout {
        float: right;
      }
    }
  }

  .lang-list {
    display: flex;
    flex-direction: column;
  }

  .hide-mobile {
    display: block !important;
  }
  .show-mobile {
    display: none !important;
  }

  .logo {
    margin-top: 1em;
  }

  @media (max-width: 577px) {
    .menu-actions {
      justify-content: space-between;
    }
    .onhover-dropdown {
      padding-right: 0;
    }
    .onhover-dropdown:before {
      display: none;
    }
    .hide-mobile,
    .topheader {
      display: none !important;
    }
    .show-mobile {
      display: block !important;
    }
    .coordinates {
      flex-direction: row;
    }
    .logo {
      max-height: 40px;
    }
  }

  .header-social {
    /*margin: 0.2em 0 1.5em 0;*/
    span {
      margin-bottom: 10px;
    }
    li {
      padding-right: 1em;
      a {
        color: white;

        &:hover {
          color: var(--theme-deafult);
        }
      }
    }
  }
}

.menu-onhover-show-div {
  background-color: black !important;
}

.hide-mobile {
  display: block;
}
@media (max-width: 577px) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 577px) {
  .topheader {
    display: flex !important;
  }
}
</style>
