import * as qs from 'qs';

export default $axios => ({
  async getUser(token) {
    const encoded = qs.stringify({
      token
    })
    try {
      return await $axios.$get(`/galaxy/login?${encoded}`);
    }catch (e) {
      return []
    }
  },
  async loginUser(username, password) {
    const data = { username, password }
    try {
      return await $axios.$post(`/galaxy/login`,data);
    }catch (e) {
      return []
    }
  }
});