const state = {
  activeOrder: null,
  monthlyLimit: null,
}
// getters
const getters = {
  activeOrder: (state) => {
    return state.activeOrder;
  },
  monthlyLimit: (state) => {
    return state.monthlyLimit;
  }
}
// mutations
const mutations = {
  setActiveOrder: (state, payload) => {
    state.activeOrder = payload;
  },
  setMonthlyLimit: (state, payload) => {
    state.monthlyLimit = payload;
  },
  modifyActiveOrder: (context, payload) => {
    state.activeOrder[payload.field] = payload.value;
  }
}
// actions
const actions = {
  setActiveOrder: (context, payload) => {
    context.commit('setActiveOrder', payload);
  },
  setMonthlyLimit: (context, payload) => {
    context.commit('setMonthlyLimit', payload);
  },
  modifyActiveOrder: (context, payload) => {
    context.commit('modifyActiveOrder', payload);
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
