<template>
  <!-- Catalog navigation in header + Subnavigation pane to show subcatalogs -->
  <div
    class="subheader-container"
    v-if="$auth.loggedIn"
    @mouseleave="
      activeSubCatalogs = undefined;
      activeCatalog = undefined;
    ">

    <div class="container-fluid sidemenu" :class="{ opened: openmobilenav }">
      <div class="mobile-back show-mobile text-right show-pointer">
        <span @click="$emit('closeMobileNav')">{{ $t("common.close") }}</span>
        <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
      </div>
      <div class="row">
        <!-- Desktop -->
        <div class="subheader col-sm-12">
<!--          <nuxt-link-->
<!--            @click.native="$emit('closeMobileNav')"-->
<!--            :to="localePath(`/catalog/${catalog.slug}`)"-->
<!--            v-for="catalog of catalogs"-->
<!--            :key="catalog.id"-->
<!--          >-->
<!--            <span @mouseover="setActiveCatalog($event, catalog)">-->
<!--              {{ getTranslatedCatalogLabel(catalog, $i18n.locale) }}-->
<!--            </span>-->
<!--          </nuxt-link>-->
          <a @mouseover="setActiveCatalog($event, catalog)" @click="redirectToCatalog($event, catalog)" v-for="catalog of catalogs" :key="catalog.id">
<!--          <a @mouseover="setActiveCatalog($event, catalog)" @mouseover="toggleActiveCatalog($event, catalog)" v-for="catalog of catalogs" :key="catalog.id">-->
            <span>
              {{ getTranslatedCatalogLabel(catalog, $i18n.locale) }}
            </span>
          </a>
          <!-- Sub catalogs -->
          <div
            class="subcatalog"
            v-if="activeSubCatalogs && activeSubCatalogs.length > 0"
            :style="{ top: subCatalogContainerY }">
            <div
              v-for="subCat of activeSubCatalogs"
              :key="subCat.id"
              :style="{ 'background-image': `url(${getImage(subCat)})` }"
              class="catalog-box"
            >
              <nuxt-link
                :to="localePath(`/catalog/${activeCatalog.slug}#${subCat.id}`)"
              >
                <div>
                  <span>{{ getTranslatedCatalogLabel(subCat, $i18n.locale) }}</span>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
        <!-- Mobile -->
        <div class="mobile-subheader mt-3">
          <nuxt-link
            @click.native="$emit('closeMobileNav')"
            :to="localePath(`/catalog/${catalog.slug}`)"
            v-for="catalog of catalogs"
            :key="catalog.id"
          >
            {{ getTranslatedCatalogLabel(catalog, $i18n.locale) }}
            <ul v-for="subCat of catalog.subCatalog" :key="subCat.id">
              <li>
                <nuxt-link
                  :to="localePath(`/catalog/${catalog.slug}#${subCat.id}`)"
                >
                  {{ getTranslatedCatalogLabel(subCat, $i18n.locale) }}
                </nuxt-link>
              </li>
            </ul>
            <div class="container-fluid row mx-auto p-0 mt-3">
              <div class="gold-gradient gold-separator col"></div>
            </div>
          </nuxt-link>

          <div class="text-center mt-4 language-switcher">
            <nuxt-link :to="switchLocalePath('fr')">{{
              $t("lang.fr")
            }}</nuxt-link>
            <nuxt-link :to="switchLocalePath('en')">{{
              $t("lang.en")
            }}</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getTranslatedCatalogLabel } from "@/utils/entity-translation";

export default {
  props: ["openmobilenav"],
  data() {
    return {
      activeCatalog: undefined,
      activeSubCatalogs: undefined,
      subCatalogContainerY: 0,
      activatedCatalogs: false,
    };
  },
  computed: {
    ...mapGetters({
      catalogs: "products/catalogs",
    }),
  },
  methods: {
    getTranslatedCatalogLabel,
    /**
     *  When clicking a link, if the catalog has subcatalogs, do nothing, but if it has not,
     *  redirect to catalog page
     */ 
    redirectToCatalog(e, catalog) {
      this.setActiveCatalog(e, catalog);
      if (!(this.activeSubCatalogs && this.activeSubCatalogs.length > 0)) {
        // if no subcatalogs : redirect to catalog article list
        this.$router.push(this.localePath(`/catalog/${catalog.slug}`));
      }
    },
    setActiveCatalog(e, catalog) {
      const line = Math.ceil(
        e.target.offsetTop / e.target.parentElement.clientHeight
      );
      const totalHeight = e.target.offsetParent.offsetHeight;
      const totalLines = totalHeight / e.target.parentElement.clientHeight;
      this.subCatalogContainerY = `${(100 / totalLines) * line}%`;

      this.activeCatalog = catalog;
      this.activeSubCatalogs = catalog.subCatalog;
    },
    /**
     * For catalogs that have subcatalogs : display and hide the subcatlog nav on click. For others 
     * that don't have subcatalogs : redirect to the catalog
     * @param e
     * @param catalog
     */
    showActiveCatalog(e, catalog) {
      this.setActiveCatalog(e, catalog);
      this.activatedCatalogs = true;
      /*if (this.activeSubCatalogs && this.activeSubCatalogs.length) {
        this.activatedCatalogs = !this.activatedCatalogs;  
      }*/
    },
    getImage(catalog) {
      return catalog.imageUrl
        ? `${process.env.BASE_API_URL}${catalog.imageUrl}` // F.Laenen : here was process.env.BASE_API_URL missing
        : require("@/assets/images/product-category-example.png");
    },
  },
};
</script>

<style lang="scss">
.language-switcher {
  display: none;
}
@import "./assets/scss/theme/variables";

.hide-mobile {
  display: block;
}
.show-mobile {
  display: none;
}

@media (max-width: 577px) {
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: block;
  }
  .show-pointer {
    cursor: pointer;
  }
  .sidemenu {
    transition: transform 200ms ease-in-out;
    transform: translateX(100%);
    &.opened {
      transform: translateX(0);
    }
    position: fixed;
    width: 75vw;
    min-width: fit-content;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 9999;
    padding: 1em;
    overflow: auto;
  }
}

.sidemenu-toggle {
  text-align: right;
  font-size: 1.5em;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.subheader-container {
  background-color: black;
}

.subheader,
.mobile-subheader {
  a {
    color: white;
    white-space: nowrap;
    text-align: center;
    padding: 0.5em 1.5em;

    &:hover {
      color: $theme-deafult;
    }
    @media (max-width: 577px) {
      color: black;
    }
  }
}

.mobile-subheader {
  display: none;
  @media (max-width: 577px) {
    display: flex;
  }
  width: 100%;
  flex-direction: column;

  a {
    text-align: left;
    padding: 0.75em 2em;
    font-weight: bold;

    .gold-separator {
      height: 1px;
    }

    ul {
      margin-top: 1em;

      a {
        margin-left: 1em;
        color: #666;
        text-transform: capitalize;
        font-weight: normal;
        font-size: 13px;
      }
    }
  }
}

.subheader {
  display: flex;
  @media (max-width: 577px) {
    display: none;
  }
  padding: 15px 15px 0 15px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  a {
    cursor: pointer;
    padding-bottom: 15px;
    &:hover {
      border-bottom: solid 2px white;
    }
  }
  .subcatalog {
    opacity: 0.97;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    min-height: 80px;
    background: white;
    z-index: 9;
    box-shadow: 0 0 5px #00000030;
    padding: 15px;
    display: flex;
    overflow: auto;
    justify-content: space-around;
    top: 100% !important;

    @media (max-width: 1200px) {
      justify-content: left;
    }
  }

  .catalog-box {
    width: 180px;
    height: 180px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-end;
    background: #00000030;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /*padding: 15px;*/
    margin: 0 10px;
    cursor: pointer;
    transition: all 100ms ease-in-out;

    &:hover {
      transform: scale(1.1);

      a {
        opacity: 1;
        color: white;
        /*text-shadow: black;*/
      }
    }

    a {
      color: black;
      width: 100%;
      height: 100%;
      display: contents;
      white-space: initial;
      text-align: center;
      font-weight: bold;
      text-shadow: 1px 1px 2px #00000050;
      opacity: 1;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        span {
          background: rgba(0, 0, 0, 0.3);
          height: 30%;
          width: 100%;
          height: fit-content;
          min-height: 40%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      /* span {
        position: absolute;
        bottom: 1em;
        right: 3em;
      }*/
    }
  }
}
</style>
