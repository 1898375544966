import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _515819f6 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _18cb7632 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _686e51f1 = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _9dbe8060 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _11960567 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6581f746 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _07cd338a = () => interopDefault(import('../pages/account/chooseRole.vue' /* webpackChunkName: "pages/account/chooseRole" */))
const _4e2f7b08 = () => interopDefault(import('../pages/order/cart.vue' /* webpackChunkName: "pages/order/cart" */))
const _21e8e0bc = () => interopDefault(import('../pages/order/checkout.vue' /* webpackChunkName: "pages/order/checkout" */))
const _3b41f27a = () => interopDefault(import('../pages/order/finalize.vue' /* webpackChunkName: "pages/order/finalize" */))
const _56a2ef6c = () => interopDefault(import('../pages/catalog/components/article-box.vue' /* webpackChunkName: "pages/catalog/components/article-box" */))
const _3220d6a0 = () => interopDefault(import('../pages/catalog/components/catalog-sidebar.vue' /* webpackChunkName: "pages/catalog/components/catalog-sidebar" */))
const _a30b73ec = () => interopDefault(import('../pages/catalog/components/catalog-topbar.vue' /* webpackChunkName: "pages/catalog/components/catalog-topbar" */))
const _5af62460 = () => interopDefault(import('../pages/home/components/banner.vue' /* webpackChunkName: "pages/home/components/banner" */))
const _77d18646 = () => interopDefault(import('../pages/home/components/blog.vue' /* webpackChunkName: "pages/home/components/blog" */))
const _10110279 = () => interopDefault(import('../pages/home/components/collection_banner.vue' /* webpackChunkName: "pages/home/components/collection_banner" */))
const _07ec93be = () => interopDefault(import('../pages/home/components/instagram.vue' /* webpackChunkName: "pages/home/components/instagram" */))
const _2b2a20fe = () => interopDefault(import('../pages/home/components/logo_slider.vue' /* webpackChunkName: "pages/home/components/logo_slider" */))
const _56a7a655 = () => interopDefault(import('../pages/home/components/product_slider.vue' /* webpackChunkName: "pages/home/components/product_slider" */))
const _77fee9d1 = () => interopDefault(import('../pages/home/components/product_tab.vue' /* webpackChunkName: "pages/home/components/product_tab" */))
const _72404302 = () => interopDefault(import('../pages/home/components/services.vue' /* webpackChunkName: "pages/home/components/services" */))
const _51f20505 = () => interopDefault(import('../pages/home/components/slider.vue' /* webpackChunkName: "pages/home/components/slider" */))
const _094f39ae = () => interopDefault(import('../pages/api/external/galaxy/Login.vue' /* webpackChunkName: "pages/api/external/galaxy/Login" */))
const _678df7f4 = () => interopDefault(import('../pages/account/login/_asRole.vue' /* webpackChunkName: "pages/account/login/_asRole" */))
const _316a0959 = () => interopDefault(import('../pages/catalog/_catalogSlug/index.vue' /* webpackChunkName: "pages/catalog/_catalogSlug/index" */))
const _beac826a = () => interopDefault(import('../pages/catalog/_catalogSlug/_articleSlug.vue' /* webpackChunkName: "pages/catalog/_catalogSlug/_articleSlug" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _515819f6,
    name: "account___fr"
  }, {
    path: "/admin",
    component: _18cb7632,
    name: "admin___fr"
  }, {
    path: "/catalog",
    component: _686e51f1,
    name: "catalog___fr"
  }, {
    path: "/cookie-policy",
    component: _9dbe8060,
    name: "cookie-policy___fr"
  }, {
    path: "/en",
    component: _11960567,
    name: "index___en"
  }, {
    path: "/home",
    component: _6581f746,
    name: "home___fr"
  }, {
    path: "/account/chooseRole",
    component: _07cd338a,
    name: "account-chooseRole___fr"
  }, {
    path: "/en/account",
    component: _515819f6,
    name: "account___en"
  }, {
    path: "/en/admin",
    component: _18cb7632,
    name: "admin___en"
  }, {
    path: "/en/catalog",
    component: _686e51f1,
    name: "catalog___en"
  }, {
    path: "/en/cookie-policy",
    component: _9dbe8060,
    name: "cookie-policy___en"
  }, {
    path: "/en/home",
    component: _6581f746,
    name: "home___en"
  }, {
    path: "/order/cart",
    component: _4e2f7b08,
    name: "order-cart___fr"
  }, {
    path: "/order/checkout",
    component: _21e8e0bc,
    name: "order-checkout___fr"
  }, {
    path: "/order/finalize",
    component: _3b41f27a,
    name: "order-finalize___fr"
  }, {
    path: "/catalog/components/article-box",
    component: _56a2ef6c,
    name: "catalog-components-article-box___fr"
  }, {
    path: "/catalog/components/catalog-sidebar",
    component: _3220d6a0,
    name: "catalog-components-catalog-sidebar___fr"
  }, {
    path: "/catalog/components/catalog-topbar",
    component: _a30b73ec,
    name: "catalog-components-catalog-topbar___fr"
  }, {
    path: "/en/account/chooseRole",
    component: _07cd338a,
    name: "account-chooseRole___en"
  }, {
    path: "/en/order/cart",
    component: _4e2f7b08,
    name: "order-cart___en"
  }, {
    path: "/en/order/checkout",
    component: _21e8e0bc,
    name: "order-checkout___en"
  }, {
    path: "/en/order/finalize",
    component: _3b41f27a,
    name: "order-finalize___en"
  }, {
    path: "/home/components/banner",
    component: _5af62460,
    name: "home-components-banner___fr"
  }, {
    path: "/home/components/blog",
    component: _77d18646,
    name: "home-components-blog___fr"
  }, {
    path: "/home/components/collection_banner",
    component: _10110279,
    name: "home-components-collection_banner___fr"
  }, {
    path: "/home/components/instagram",
    component: _07ec93be,
    name: "home-components-instagram___fr"
  }, {
    path: "/home/components/logo_slider",
    component: _2b2a20fe,
    name: "home-components-logo_slider___fr"
  }, {
    path: "/home/components/product_slider",
    component: _56a7a655,
    name: "home-components-product_slider___fr"
  }, {
    path: "/home/components/product_tab",
    component: _77fee9d1,
    name: "home-components-product_tab___fr"
  }, {
    path: "/home/components/services",
    component: _72404302,
    name: "home-components-services___fr"
  }, {
    path: "/home/components/slider",
    component: _51f20505,
    name: "home-components-slider___fr"
  }, {
    path: "/api/external/galaxy/Login",
    component: _094f39ae,
    name: "api-external-galaxy-Login___fr"
  }, {
    path: "/en/catalog/components/article-box",
    component: _56a2ef6c,
    name: "catalog-components-article-box___en"
  }, {
    path: "/en/catalog/components/catalog-sidebar",
    component: _3220d6a0,
    name: "catalog-components-catalog-sidebar___en"
  }, {
    path: "/en/catalog/components/catalog-topbar",
    component: _a30b73ec,
    name: "catalog-components-catalog-topbar___en"
  }, {
    path: "/en/home/components/banner",
    component: _5af62460,
    name: "home-components-banner___en"
  }, {
    path: "/en/home/components/blog",
    component: _77d18646,
    name: "home-components-blog___en"
  }, {
    path: "/en/home/components/collection_banner",
    component: _10110279,
    name: "home-components-collection_banner___en"
  }, {
    path: "/en/home/components/instagram",
    component: _07ec93be,
    name: "home-components-instagram___en"
  }, {
    path: "/en/home/components/logo_slider",
    component: _2b2a20fe,
    name: "home-components-logo_slider___en"
  }, {
    path: "/en/home/components/product_slider",
    component: _56a7a655,
    name: "home-components-product_slider___en"
  }, {
    path: "/en/home/components/product_tab",
    component: _77fee9d1,
    name: "home-components-product_tab___en"
  }, {
    path: "/en/home/components/services",
    component: _72404302,
    name: "home-components-services___en"
  }, {
    path: "/en/home/components/slider",
    component: _51f20505,
    name: "home-components-slider___en"
  }, {
    path: "/en/api/external/galaxy/Login",
    component: _094f39ae,
    name: "api-external-galaxy-Login___en"
  }, {
    path: "/en/account/login/:asRole?",
    component: _678df7f4,
    name: "account-login-asRole___en"
  }, {
    path: "/account/login/:asRole?",
    component: _678df7f4,
    name: "account-login-asRole___fr"
  }, {
    path: "/en/catalog/:catalogSlug",
    component: _316a0959,
    name: "catalog-catalogSlug___en"
  }, {
    path: "/en/catalog/:catalogSlug/:articleSlug",
    component: _beac826a,
    name: "catalog-catalogSlug-articleSlug___en"
  }, {
    path: "/catalog/:catalogSlug",
    component: _316a0959,
    name: "catalog-catalogSlug___fr"
  }, {
    path: "/catalog/:catalogSlug/:articleSlug",
    component: _beac826a,
    name: "catalog-catalogSlug-articleSlug___fr"
  }, {
    path: "/",
    component: _11960567,
    name: "index___fr"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
