export default $axios => ({
  async postAddress(address) {
    return $axios.$post('/user/address', {
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      countryCode: address.countryCode,
      fullName: address.fullName,
      postalCode: address.postalCode,
      region: address.region
    });
  },
  async patchAddress(address) {
    return $axios.$patch(`/user/address/${address.id}`, {
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      countryCode: address.countryCode,
      fullName: address.fullName,
      postalCode: address.postalCode,
      region: address.region,
      default: address.default,
    });
  },
  async setDefault(addressId, defaultValue) {
    return $axios.$patch(`/user/address/${addressId}`, {
      default: defaultValue,
    });
  },
  async deleteAddress(addressId) {
    return $axios.$delete(`/user/address/${addressId}`);
  },
});