<template>
  <div>
    <label :title="$t('cookie.functionalRef')">
      <input type="checkbox" v-model="cookieFunctional" disabled> {{ $t('cookie.functional') }}
    </label>
    <label :title="$t('cookie.statisticsRef')">
      <input type="checkbox" v-model="cookieStatistics"> {{ $t('cookie.statistics') }}
    </label>
    <br>
    <button class="btn btn-primary" @click="savePref">
      {{ $t('cookie.savePref') }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookieFunctional: true,
      cookieStatistics: true,
    };
  },
  mounted() {

  },
  methods: {
    savePref() {
      window.localStorage.setItem('cookie-saved', true);
      window.localStorage.setItem('cookie-statistics', this.cookieStatistics);
      this.$emit('saved');
    }
  }
}
</script>
