<template>
  <div class="cookie-box" :class="{ hidden: cookieSaved }">
    <p>
      {{ $t('cookie.useCookie') }}.<br>
      <nuxt-link :to="{ path: '/cookie-policy' }">{{ $t('navigation.cookiePolicy') }}</nuxt-link>
    </p>
    <CookieForm @saved="saved" />
  </div>
</template>

<script>
import CookieForm from './widgets/cookie-form.vue';

export default {
  data() {
    return {
      cookieSaved: false,
    }
  },
  components: {
    CookieForm
  },
  mounted() {
    this.cookieSaved = window.localStorage.getItem('cookie-statistics');
  },
  methods: {
    saved() {
      this.cookieSaved = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-box {
  position: fixed;
  background: white;
  bottom: 0;
  left: 20px;
  padding: 3em;
  z-index: 99999;
  box-shadow: 0px 0px 2px #999;

  &.hidden {
    display: none;
  }
}
</style>