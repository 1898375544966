
const state = {
  cart: [],
}
// getters
const getters = {
  cartState: (state) => {
    return state;
  },
  cartItems: (state) => {
    return state.cart;
  },
  cartItemsCount: (state) => {
    return state.cart.reduce((total, articleInCart) => {
      return total + articleInCart.quantity;
    }, 0);
  },
  cartOriginalAmount: (state) => {
    const total = state.cart.reduce((total, articleInCart) => total + articleInCart.article.priceTTC * articleInCart.quantity, 0);
    return Math.round(total * 100) / 100;
  },
  cartTotalAmount: (state) => {
    return getters.cartOriginalAmount(state);
  }
}
// mutations
const mutations = {
  addToCart: (state, payload) => {
    // payload is the article
    const cartIndex = state.cart.findIndex(articleInCart => articleInCart.article.id === payload.id);
    // if article not already present : qty = 1, else, increment
    if (cartIndex < 0) {
      state.cart.push({
        article: payload,
        quantity: 1,
      });
    } else {
      state.cart[cartIndex].quantity += 1;
    }
  },
  updateCartQuantity: (state, payload) => {
    const cartIndex = state.cart.findIndex(articleInCart => articleInCart.article.id === payload.id);
    if (cartIndex >= 0) {
      state.cart[cartIndex].quantity = payload.quantity;
    }
  },
  /**
   * 
   * @param state
   * @param payload the article or cartItem to remove
   */
  removeCartItem: (state, payload) => {
    let articleToRemove;
    // either this function is passed a cartItem, either an article
    if (payload.article) {
      articleToRemove = payload.article;
    } else {
      articleToRemove = payload;
    }
    const indexToRemove = state.cart.findIndex(cartItem => cartItem.article.id === articleToRemove.id);
    //state.cart.splice(indexToRemove, 1);
    // Perform additional checks
      // Removes also articles that are offered from this one 
    let filteredCart = [...state.cart];
    filteredCart = filteredCart.filter(function(cartItem, index) {
      const article = cartItem.article;
      return article.offeredFrom !== articleToRemove.sageId && index !== indexToRemove;
    });
    state.cart = filteredCart;
  },
  clearCart: (state) => {
    state.cart = [];
  }
};

// actions
const actions = {
  addToCart: (context, payload) => {
     // Prevent add to cart if forbidden (example: ATP-38)
    if (payload.cannotBeAddedToCart) {
      // eslint-disable-next-line no-console
      console.error('This article is marked as cannotBeAddedToCart and thus cannot be added to cart');
      return;
    } else {
      context.commit('addToCart', payload);
    }
  },
  updateCartQuantity: (context, payload) => {
    context.commit('updateCartQuantity', payload)
  },
  removeCartItem: (context, payload) => {
    context.commit('removeCartItem', payload)
  },
  clearCart: (context) => {
    context.commit('clearCart');
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
