export default {
  namespaced: true,
  state:{
    currentPage: 1
  },
  getters:{
    
  },
  actions:{
    
  },
  mutations:{
    setCurrentPage(state,page){
      state.currentPage = page
    }
  }
}